<template>
  <div class="keepRecord">

         <van-tabs v-model="active" animated sticky type="card">
            <van-tab title="卖料定价" name="Price">
              <div class="top">
                <van-tabs v-model="activeName" animated sticky swipeable >
                  <van-tab
                    v-for="(item,index) in name"
                    :title="item == 'ALL'?'全部':item == 'SUSPENSE,UNDELIVERED'?'进行中':'已完成'"
                    :name="item"
                    :key="index">

<!--                    <keep-alive>-->
<!--                      <buyOrderPrice :activeName="activeName" @getData="getData" ref="buyOrder"/>-->
<!--                    </keep-alive>-->
                    <keep-alive>
                      <sellingPrice :activeName='activeName'/>
                    </keep-alive>

                  </van-tab>
                </van-tabs>
              </div>

            </van-tab>
            <van-tab title="卖料结算" name="Settlement">
                <keep-alive>
                    <salesSettlement :isWaitHandle="isWaitHandle" />
                </keep-alive>
            </van-tab>
         </van-tabs>
    <!-- 右侧结算 -->
<!--    <div class="tabsRight" >
      <el-tooltip effect="dark" content="无法交付现货"  placement="top-start">
        <van-icon name="question-o" color="#FF2D2E"  />
      </el-tooltip>
      <span @click="tobreakcontract">结算</span>
    </div>-->
         <!-- 左侧图标 -->
         <div class="tabsLeftIcon" @click="back">
            <van-icon name="arrow-left" />
        </div>


  </div>
</template>

<script>
import salesSettlement from './salesSettlement/index.vue'
import sellingPrice from './sellingPrice/index.vue'
export default {
            components:{
                    salesSettlement,
                    sellingPrice
            },
            data() {
                return {
                    active:'Price',
                    activeName:'ALL',
                    // 全部 待审核  待送货 已完成  已拒绝
                    name:['ALL','SUSPENSE,UNDELIVERED','DONE,CANCEL,VIOLATE'],
                  isWaitHandle: false
                }
            },
            mounted() {
               this.$Loading.show({text:'加载中...'})

                this.active = this.$route.query.active?this.$route.query.active:'Price'
              this.isWaitHandle = this.$route.query.isWaitHandle ? this.$route.query.isWaitHandle : false
            },
            methods:{
                back(){
                    this.$router.push({
                        name:'person'
                    })
                },
                tobreakcontract(){
                  this.$router.push({
                    name:'breakContract',
                    query:{
                      active:'sell',
                      path: "sellSettlement"
                    }
                  })
                }
            },
}
</script>

<style lang="scss" scoped>
::v-deep{

    .van-tabs__wrap{
        height: 88px;
        padding: 0 220px;
        background-color: #fff;
    }
    .van-tabs__line{
        display: none;
    }
    .van-tabs__nav--line {
        padding-bottom: 0 !important;
}
    .van-tabs__nav{
        height: 50px;
        margin-top: 16px;
        border-radius: 10px ;
    }
    .van-tab{
        height: 100%;
        line-height: 50px;
        font-size: 28px;
        padding: 0;
    }
  .top{
    .van-tabs__wrap{
      height: 80px;
      padding: 0;
      background-color: #fff;
    }
    .van-tabs__line{
      display: block;
      bottom:0;
      height: 4px;
    }
    .van-tabs__nav--line {
      margin: 0;
      padding-bottom: 0 !important;
      background-color: #fff;
    }
    .van-tabs__nav{
      border: none;
      height: 80px;
    }
    .van-tab{
      background-color: #fff;
      height: 100%;
      color: #999999;
      line-height: 70px;
      font-size: 26px;
      padding: 0;
    }
    .van-tab--active{
      background-color: #fff;
      color: #242424;
    }

  }
}
.keepRecord{
    background-color: #f5f5f5;
}
.tabsLeftIcon{
    position: fixed;
    top: 30px;
    left: 10px;
    z-index: 9999;
    .van-icon{
        font-size: 32px;
    }
}
.tabsRight {
  position: fixed;
  z-index: 9999;
  top: 18px;
  right: 30px;
  height: 50px;
  font-size: 28px;
  line-height: 50px;
}
</style>
